tinymce.addI18n('pt_BR',{
"Redo": "Refazer",
"Undo": "Desfazer",
"Cut": "Cortar",
"Copy": "Copiar",
"Paste": "Colar",
"Select all": "Selecionar tudo",
"New document": "Novo documento",
"Ok": "Ok",
"Cancel": "Cancelar",
"Visual aids": "Ajuda visual",
"Bold": "Negrito",
"Italic": "It\u00e1lico",
"Underline": "Sublinhado",
"Strikethrough": "Tachado",
"Superscript": "Sobrescrito",
"Subscript": "Subscrito",
"Clear formatting": "Limpar formata\u00e7\u00e3o",
"Align left": "Alinhar \u00e0 esquerda",
"Align center": "Centralizar",
"Align right": "Alinhar \u00e0 direita",
"Justify": "Justificar",
"Bullet list": "Lista n\u00e3o ordenada",
"Numbered list": "Lista ordenada",
"Decrease indent": "Diminuir recuo",
"Increase indent": "Aumentar recuo",
"Close": "Fechar",
"Formats": "Formatos",
"Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X\/C\/V keyboard shortcuts instead.": "Seu navegador n\u00e3o suporta acesso direto \u00e0 \u00e1rea de transfer\u00eancia. Por favor use os atalhos Ctrl+X - C - V do teclado",
"Headers": "Cabe\u00e7alhos",
"Header 1": "Cabe\u00e7alho 1",
"Header 2": "Cabe\u00e7alho 2",
"Header 3": "Cabe\u00e7alho 3",
"Header 4": "Cabe\u00e7alho 4",
"Header 5": "Cabe\u00e7alho 5",
"Header 6": "Cabe\u00e7alho 6",
"Headings": "T\u00edtulos",
"Heading 1": "T\u00edtulo 1",
"Heading 2": "T\u00edtulo 2",
"Heading 3": "T\u00edtulo 3",
"Heading 4": "T\u00edtulo 4",
"Heading 5": "T\u00edtulo 5",
"Heading 6": "T\u00edtulo 6",
"Preformatted": "Pr\u00e9-formatado",
"Div": "Div",
"Pre": "Pre",
"Code": "C\u00f3digo",
"Paragraph": "Par\u00e1grafo",
"Blockquote": "Aspas",
"Inline": "Em linha",
"Blocks": "Blocos",
"Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.": "O comando colar est\u00e1 agora em modo texto plano. O conte\u00fado ser\u00e1 colado como texto plano at\u00e9 voc\u00ea desligar esta op\u00e7\u00e3o.",
"Fonts": "Fontes",
"Font Sizes": "Tamanhos da fonte",
"Class": "Classe",
"Browse for an image": "Procure uma imagem",
"OR": "OU",
"Drop an image here": "Solte uma imagem aqui",
"Upload": "Carregar",
"Block": "Bloco",
"Align": "Alinhamento",
"Default": "Padr\u00e3o",
"Circle": "C\u00edrculo",
"Disc": "Disco",
"Square": "Quadrado",
"Lower Alpha": "a. b. c. ...",
"Lower Greek": "\u03b1. \u03b2. \u03b3. ...",
"Lower Roman": "i. ii. iii. ...",
"Upper Alpha": "A. B. C. ...",
"Upper Roman": "I. II. III. ...",
"Anchor...": "\u00c2ncora...",
"Name": "Nome",
"Id": "Id",
"Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.": "Id deve come\u00e7ar com uma letra, seguido apenas por letras, n\u00fameros, tra\u00e7os, pontos, dois pontos ou sublinhados.",
"You have unsaved changes are you sure you want to navigate away?": "Voc\u00ea tem mudan\u00e7as n\u00e3o salvas. Voc\u00ea tem certeza que deseja sair?",
"Restore last draft": "Restaurar \u00faltimo rascunho",
"Special character...": "Caractere especial...",
"Source code": "C\u00f3digo fonte",
"Insert\/Edit code sample": "Inserir\/Editar c\u00f3digo de exemplo",
"Language": "Idioma",
"Code sample...": "Exemplo de c\u00f3digo...",
"Color Picker": "Seletor de Cores",
"R": "R",
"G": "G",
"B": "B",
"Left to right": "Da esquerda para a direita",
"Right to left": "Da direita para a esquerda",
"Emoticons...": "Emojis...",
"Metadata and Document Properties": "Metadados e Propriedades do Documento",
"Title": "T\u00edtulo",
"Keywords": "Palavras-chave",
"Description": "Descri\u00e7\u00e3o",
"Robots": "Rob\u00f4s",
"Author": "Autor",
"Encoding": "Codifica\u00e7\u00e3o",
"Fullscreen": "Tela cheia",
"Action": "A\u00e7\u00e3o",
"Shortcut": "Atalho",
"Help": "Ajuda",
"Address": "Endere\u00e7o",
"Focus to menubar": "Foco no menu",
"Focus to toolbar": "Foco na barra de ferramentas",
"Focus to element path": "Foco no caminho do elemento",
"Focus to contextual toolbar": "Foco na barra de ferramentas contextual",
"Insert link (if link plugin activated)": "Inserir link (se o plugin de link estiver ativado)",
"Save (if save plugin activated)": "Salvar (se o plugin de salvar estiver ativado)",
"Find (if searchreplace plugin activated)": "Procurar (se o plugin de procurar e substituir estiver ativado)",
"Plugins installed ({0}):": "Plugins instalados ({0}):",
"Premium plugins:": "Plugins premium:",
"Learn more...": "Saiba mais...",
"You are using {0}": "Voc\u00ea est\u00e1 usando {0}",
"Plugins": "Plugins",
"Handy Shortcuts": "Atalhos \u00fateis",
"Horizontal line": "Linha horizontal",
"Insert\/edit image": "Inserir\/editar imagem",
"Image description": "Inserir descri\u00e7\u00e3o",
"Source": "Endere\u00e7o da imagem",
"Dimensions": "Dimens\u00f5es",
"Constrain proportions": "Manter propor\u00e7\u00f5es",
"General": "Geral",
"Advanced": "Avan\u00e7ado",
"Style": "Estilo",
"Vertical space": "Espa\u00e7amento vertical",
"Horizontal space": "Espa\u00e7amento horizontal",
"Border": "Borda",
"Insert image": "Inserir imagem",
"Image...": "Imagem...",
"Image list": "Lista de Imagens",
"Rotate counterclockwise": "Girar em sentido hor\u00e1rio",
"Rotate clockwise": "Girar em sentido anti-hor\u00e1rio",
"Flip vertically": "Virar verticalmente",
"Flip horizontally": "Virar horizontalmente",
"Edit image": "Editar imagem",
"Image options": "Op\u00e7\u00f5es de Imagem",
"Zoom in": "Aumentar zoom",
"Zoom out": "Diminuir zoom",
"Crop": "Cortar",
"Resize": "Redimensionar",
"Orientation": "Orienta\u00e7\u00e3o",
"Brightness": "Brilho",
"Sharpen": "Aumentar nitidez",
"Contrast": "Contraste",
"Color levels": "N\u00edveis de cor",
"Gamma": "Gama",
"Invert": "Inverter",
"Apply": "Aplicar",
"Back": "Voltar",
"Insert date\/time": "Inserir data\/hora",
"Date\/time": "data\/hora",
"Insert\/Edit Link": "Inserir\/Editar Link",
"Insert\/edit link": "Inserir\/editar link",
"Text to display": "Texto para mostrar",
"Url": "Url",
"Open link in...": "Abrir link em...",
"Current window": "Janela atual",
"None": "Nenhum",
"New window": "Nova janela",
"Remove link": "Remover link",
"Anchors": "\u00c2ncoras",
"Link...": "Link...",
"Paste or type a link": "Cole ou digite um Link",
"The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?": "The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?",
"The URL you entered seems to be an external link. Do you want to add the required http:\/\/ prefix?": "A URL que voc\u00ea informou parece ser um link externo. Deseja incluir o prefixo http:\/\/?",
"Link list": "Lista de Links",
"Insert video": "Inserir v\u00eddeo",
"Insert\/edit video": "Inserir\/editar v\u00eddeo",
"Insert\/edit media": "Inserir\/editar imagem",
"Alternative source": "Fonte alternativa",
"Alternative source URL": "Endere\u00e7o URL alternativo",
"Media poster (Image URL)": "Post de m\u00eddia (URL da Imagem)",
"Paste your embed code below:": "Insira o c\u00f3digo de incorpora\u00e7\u00e3o abaixo:",
"Embed": "Incorporar",
"Media...": "M\u00eddia...",
"Nonbreaking space": "Espa\u00e7o n\u00e3o separ\u00e1vel",
"Page break": "Quebra de p\u00e1gina",
"Paste as text": "Colar como texto",
"Preview": "Pr\u00e9-visualizar",
"Print...": "Imprimir...",
"Save": "Salvar",
"Find": "Localizar",
"Replace with": "Substituir por",
"Replace": "Substituir",
"Replace all": "Substituir tudo",
"Previous": "Anterior",
"Next": "Pr\u00f3ximo",
"Find and replace...": "Encontrar e substituir...",
"Could not find the specified string.": "N\u00e3o foi poss\u00edvel encontrar o termo especificado",
"Match case": "Diferenciar mai\u00fasculas e min\u00fasculas",
"Find whole words only": "Encontrar somente palavras inteiras",
"Spell check": "Verifica\u00e7\u00e3o ortogr\u00e1fica",
"Ignore": "Ignorar",
"Ignore all": "Ignorar tudo",
"Finish": "Finalizar",
"Add to Dictionary": "Adicionar ao Dicion\u00e1rio",
"Insert table": "Inserir tabela",
"Table properties": "Propriedades da tabela",
"Delete table": "Excluir tabela",
"Cell": "C\u00e9lula",
"Row": "Linha",
"Column": "Coluna",
"Cell properties": "Propriedades da c\u00e9lula",
"Merge cells": "Agrupar c\u00e9lulas",
"Split cell": "Dividir c\u00e9lula",
"Insert row before": "Inserir linha antes",
"Insert row after": "Inserir linha depois",
"Delete row": "Excluir linha",
"Row properties": "Propriedades da linha",
"Cut row": "Recortar linha",
"Copy row": "Copiar linha",
"Paste row before": "Colar linha antes",
"Paste row after": "Colar linha depois",
"Insert column before": "Inserir coluna antes",
"Insert column after": "Inserir coluna depois",
"Delete column": "Excluir coluna",
"Cols": "Colunas",
"Rows": "Linhas",
"Width": "Largura",
"Height": "Altura",
"Cell spacing": "Espa\u00e7amento da c\u00e9lula",
"Cell padding": "Espa\u00e7amento interno da c\u00e9lula",
"Show caption": "Mostrar descri\u00e7\u00e3o",
"Left": "Esquerdo",
"Center": "Centro",
"Right": "Direita",
"Cell type": "Tipo de c\u00e9lula",
"Scope": "Escopo",
"Alignment": "Alinhamento",
"H Align": "Alinhamento H",
"V Align": "Alinhamento V",
"Top": "Superior",
"Middle": "Meio",
"Bottom": "Inferior",
"Header cell": "C\u00e9lula cabe\u00e7alho",
"Row group": "Agrupar linha",
"Column group": "Agrupar coluna",
"Row type": "Tipo de linha",
"Header": "Cabe\u00e7alho",
"Body": "Corpo",
"Footer": "Rodap\u00e9",
"Border color": "Cor da borda",
"Insert template...": "Inserir modelo...",
"Templates": "Modelos",
"Template": "Modelo",
"Text color": "Cor do texto",
"Background color": "Cor do fundo",
"Custom...": "Personalizado...",
"Custom color": "Cor personalizada",
"No color": "Nenhuma cor",
"Remove color": "Remover cor",
"Table of Contents": "\u00edndice de Conte\u00fado",
"Show blocks": "Mostrar blocos",
"Show invisible characters": "Exibir caracteres invis\u00edveis",
"Word count": "Contador de palavras",
"Count": "Contar",
"Document": "Documento",
"Selection": "Sele\u00e7\u00e3o",
"Words": "Palavras",
"Words: {0}": "Palavras: {0}",
"{0} words": "{0} palavras",
"File": "Arquivo",
"Edit": "Editar",
"Insert": "Inserir",
"View": "Visualizar",
"Format": "Formatar",
"Table": "Tabela",
"Tools": "Ferramentas",
"Powered by {0}": "Distribu\u00eddo por  {0}",
"Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help": "\u00c1rea de texto formatado. Pressione ALT-F9 para exibir o menu, ALT-F10 para exibir a barra de ferramentas ou ALT-0 para exibir a ajuda",
"Image title": "T\u00edtulo da imagem",
"Border width": "Espessura da borda",
"Border style": "Estilo da borda",
"Error": "Erro",
"Warn": "Aviso",
"Valid": "V\u00e1lido",
"To open the popup, press Shift+Enter": "Para abrir a popup, aperte Shit+Enter",
"Rich Text Area. Press ALT-0 for help.": "\u00c1rea Rich Text. Aperte ALT-0 para ajuda.",
"System Font": "Fonte do sistema",
"Failed to upload image: {0}": "Falha no upload da imagem: {0}",
"Failed to load plugin: {0} from url {1}": "Falha ao carregar plugin: {0} da url {1}",
"Failed to load plugin url: {0}": "Falha ao carregar url do plugin: {0}",
"Failed to initialize plugin: {0}": "Falha ao inicializar plugin: {0}",
"example": "exemplo",
"Search": "Pesquisar",
"All": "Tudo",
"Currency": "Moeda",
"Text": "Texto",
"Quotations": "Cita\u00e7\u00f5es",
"Mathematical": "Matem\u00e1tico",
"Extended Latin": "Latino estendido",
"Symbols": "S\u00edmbolos",
"Arrows": "Setas",
"User Defined": "Definido pelo Usu\u00e1rio",
"dollar sign": "s\u00edmbolo de d\u00f3lar",
"currency sign": "s\u00edmbolo de moeda",
"euro-currency sign": "s\u00edmbolo de euro",
"colon sign": "s\u00edmbolo de dois pontos",
"cruzeiro sign": "s\u00edmbolo de cruzeiro",
"french franc sign": "s\u00edmbolo de franco franc\u00eas",
"lira sign": "s\u00edmbolo de lira",
"mill sign": "s\u00edmbolo do mill",
"naira sign": "s\u00edmbolo da naira",
"peseta sign": "s\u00edmbolo da peseta",
"rupee sign": "s\u00edmbolo da r\u00fapia",
"won sign": "s\u00edmbolo do won",
"new sheqel sign": "s\u00edmbolo do novo sheqel",
"dong sign": "s\u00edmbolo do dong",
"kip sign": "s\u00edmbolo do kip",
"tugrik sign": "s\u00edmbolo do tugrik",
"drachma sign": "s\u00edmbolo do drachma",
"german penny symbol": "s\u00edmbolo de centavo alem\u00e3o",
"peso sign": "s\u00edmbolo do peso",
"guarani sign": "s\u00edmbolo do guarani",
"austral sign": "s\u00edmbolo do austral",
"hryvnia sign": "s\u00edmbolo do hryvnia",
"cedi sign": "s\u00edmbolo do cedi",
"livre tournois sign": "s\u00edmbolo do livre tournois",
"spesmilo sign": "s\u00edmbolo do spesmilo",
"tenge sign": "s\u00edmbolo do tenge",
"indian rupee sign": "s\u00edmbolo de r\u00fapia indiana",
"turkish lira sign": "s\u00edmbolo de lira turca",
"nordic mark sign": "s\u00edmbolo do marco n\u00f3rdico",
"manat sign": "s\u00edmbolo do manat",
"ruble sign": "s\u00edmbolo do rublo",
"yen character": "caractere do yen",
"yuan character": "caractere do yuan",
"yuan character, in hong kong and taiwan": "caractere do yuan, em Hong Kong e Taiwan",
"yen\/yuan character variant one": "varia\u00e7\u00e3o do caractere de yen\/yuan",
"Loading emoticons...": "Carregando emojis...",
"Could not load emoticons": "N\u00e3o foi poss\u00edvel carregar emojis",
"People": "Pessoas",
"Animals and Nature": "Animais e Natureza",
"Food and Drink": "Comida e Bebida",
"Activity": "Atividade",
"Travel and Places": "Viagem e Lugares",
"Objects": "Objetos",
"Flags": "Bandeiras",
"Characters": "Caracteres",
"Characters (no spaces)": "Caracteres (sem espa\u00e7os)",
"{0} characters": "{0} caracteres",
"Error: Form submit field collision.": "Erro: colis\u00e3o de bot\u00e3o de envio do formul\u00e1rio.",
"Error: No form element found.": "Erro: elemento de formul\u00e1rio n\u00e3o encontrado.",
"Update": "Atualizar",
"Color swatch": "Amostra de cor",
"Turquoise": "Turquesa",
"Green": "Verde",
"Blue": "Azul",
"Purple": "Roxo",
"Navy Blue": "Azul marinho",
"Dark Turquoise": "Turquesa escuro",
"Dark Green": "Verde escuro",
"Medium Blue": "Azul m\u00e9dio",
"Medium Purple": "Roxo m\u00e9dio",
"Midnight Blue": "Azul meia-noite",
"Yellow": "Amarelo",
"Orange": "Laranja",
"Red": "Vermelho",
"Light Gray": "Cinza claro",
"Gray": "Cinza",
"Dark Yellow": "Amarelo escuro",
"Dark Orange": "Laranja escuro",
"Dark Red": "Vermelho escuro",
"Medium Gray": "Cinza m\u00e9dio",
"Dark Gray": "Cinza escuro",
"Light Green": "Verde claro",
"Light Yellow": "Amarelo claro",
"Light Red": "Vermelho claro",
"Light Purple": "Roxo claro",
"Light Blue": "Azul claro",
"Dark Purple": "Roxo escuro",
"Dark Blue": "Azul escuro",
"Black": "Preto",
"White": "Branco",
"Switch to or from fullscreen mode": "Abrir ou fechar modo de tela cheia",
"Open help dialog": "Abrir janela de ajuda",
"history": "hist\u00f3rico",
"styles": "estilos",
"formatting": "formata\u00e7\u00e3o",
"alignment": "alinhamento",
"indentation": "indenta\u00e7\u00e3o",
"permanent pen": "caneta permanente",
"comments": "coment\u00e1rios",
"Format Painter": "Pincel de Formata\u00e7\u00e3o",
"Insert\/edit iframe": "Inserir\/editar iframe",
"Capitalization": "Capitaliza\u00e7\u00e3o",
"lowercase": "min\u00fasculos",
"UPPERCASE": "MAI\u00daSCULAS",
"Title Case": "T\u00edtulo do caso",
"Permanent Pen Properties": "Propriedades da caneta permanente",
"Permanent pen properties...": "Propriedades de caneta permanentes...",
"Font": "Fonte",
"Size": "Tamanho",
"More...": "Mais...",
"Spellcheck Language": "Idioma de verifica\u00e7\u00e3o ortogr\u00e1fica",
"Select...": "Selecionar...",
"Preferences": "Prefer\u00eancias",
"Yes": "Sim",
"No": "N\u00e3o",
"Keyboard Navigation": "Navega\u00e7\u00e3o por Teclado",
"Version": "Vers\u00e3o",
"Anchor": "\u00c2ncora",
"Special character": "Caracteres especiais",
"Code sample": "Exemplo de c\u00f3digo",
"Color": "Cor",
"Emoticons": "Emoticons",
"Document properties": "Propriedades do documento",
"Image": "Imagem",
"Insert link": "Inserir link",
"Target": "Alvo",
"Link": "Link",
"Poster": "Autor",
"Media": "imagem",
"Print": "Imprimir",
"Prev": "Anterior",
"Find and replace": "Localizar e substituir",
"Whole words": "Palavras inteiras",
"Spellcheck": "Corretor ortogr\u00e1fico",
"Caption": "Legenda",
"Insert template": "Inserir modelo"
});