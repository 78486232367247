let bricksBuilder;
let brickController;

function UmountBrick() {
  if (brickController != undefined && bricksBuilder != undefined && bricksBuilder != null && bricksBuilder.isInitialized()) {
    brickController.unmount();
  }
}

function LoadbricksFull(mpToken, planPaymentId, planPaymentValor, bearerToken) {
  UmountBrick();
  const mp = new MercadoPago(mpToken, { locale: 'pt-BR' });

  bricksBuilder = mp.bricks();
  const renderCardPaymentBrick = async (bricksBuilder) => {

    var amountPlan = planPaymentValor;

    const settings = {
      customization: {
        visual: {
          // hideFormTitle: true
          texts: {
            formTitle: 'Cartão de Crédito - R$' + amountPlan.toFixed(2),
            installmentsSectionTitle: 'string',
          }
        },
        paymentMethods: {
          minInstallments: 1,
          maxInstallments: 1,
          types: {
            excluded: ['debit_card']
          },
        },
      },
      initialization: {
        amount: amountPlan,
        autoMount: true,
      },

      style: {
        theme: 'bootstrap' // | 'dark' | 'bootstrap' | 'flat'
      },
      callbacks: {
        onReady: () => {
          // callback chamado quando o Brick estiver pronto
        },
        onSubmit: (cardFormData) => {
          // callback chamado o usuário clicar no botão de submissão dos dados

          return new Promise((resolve, reject) => {
            var domainUrlApi = window.location.origin;

            if (domainUrlApi.includes("http://localhost"))
              domainUrlApi = 'https://localhost:44317';

            if (domainUrlApi.includes("https://hml.plataformaesa.com.br"))
              domainUrlApi = 'https://apibeta.plataformaesa.com.br';

            if (domainUrlApi.includes("https://web.plataformaesa.com.br"))
              domainUrlApi = 'https://api.plataformaesa.com.br';

            var urlAPI = domainUrlApi + '/api/v1/payment/' + planPaymentId + '/process-payment';

            fetch(urlAPI, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + bearerToken,
              },
              body: JSON.stringify(cardFormData)
            })
              .then(response => response.json())
              .then((body) => {
                if (body.mensagens != null) {
                  showMessageError(body.mensagens[0])
                } else {
                  showMessageSuccess();
                }
                resolve();
              })
              .catch((error) => {

                showMessageError(error);
                reject();
              })
          });
        },
        onError: (error) => {
          // callback chamado para todos os casos de erro do Brick
        },
      },
    };
    brickController = await bricksBuilder.create('cardPayment', 'cardPaymentBrick_container', settings);
  };
  renderCardPaymentBrick(bricksBuilder);
}

function showMessageSuccess() {
  Swal.fire({
    title: 'Sucesso!',
    text: 'Pagamento efetuado com sucesso! Refaça o login para atualizar a sua configuração.',
    icon: 'success',
    allowOutsideClick: false,
    allowEscapeKey: false,
    confirmButtonText: 'OK'
  }).then((result) => {
    document.getElementById("btn-voltar").click();
  })
}

function showMessageError(message) {
  Swal.fire({
    title: 'Ops! Refaça a operação.',
    text: message,
    icon: 'error',
    allowOutsideClick: false,
    allowEscapeKey: false,
    confirmButtonText: 'OK'
  }).then((result) => {
    document.getElementById("btn-load").click();
  })
}
